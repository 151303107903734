<template>
  <b-card>
    <b-tabs v-model="tabIndex">
      <b-tab active @click="clickTab">
        <template #title>
          <span class="d-none d-sm-inline">Lista de reservas</span>
        </template>
        <OwnersBookings @set-tab-index="setTabIndex" :bookingId="bookingId"/>        
      </b-tab>      
      <!-- <b-tab @click="clickTabPendings" v-if="can('fivesclub_owner_booking_show_booking_auth')">
        <template #title >
          <div >
            Reservaciones pendientes
          </div>
        </template>
        <PendingReservations @set-tab-index="setTabIndex"/>
      </b-tab>  -->
      <b-tab @click="clickTab" >
        <template #title >
          <div v-can="'fivesclub_owner_booking_show_booking_booking_request'">
            Petición de reservas
          </div>
        </template>
        <BookingsRequests @set-tab-index="setTabIndex" :filterParams="filterParams" v-can="'fivesclub_owner_booking_show_booking_booking_request'"/>
      </b-tab>  
      <!-- <b-tab @click="clickTab" >
        <template #title >
          <div v-can="'fivesclub_owner_booking_show_booking_booking_request'">
            Petición de reservas2
          </div>
        </template>
        <BookingRequests v-can="'fivesclub_owner_booking_show_booking_booking_request'"/>
      </b-tab>   -->
    </b-tabs>
  </b-card> 
</template>

<script>
import { mapState, mapActions,mapMutations  } from "vuex"
import { acl } from "@/modules/auth/mixins/acl"
import OwnersBookings from '@/modules/fivesClub/components/catalogs/ownersBookings/list/OwnersBookings'
import PendingReservations from '@/modules/fivesClub/components/catalogs/ownersBookings/pendings/PendingReservations'
import BookingsRequests from '@/modules/fivesClub/components/catalogs/ownersBookings/requests/BookingsRequests'
import BookingRequests from '@/modules/fivesClub/components/catalogs/ownersBookings/BookingRequests'


export default {
  mixins: [ acl],

  components:{    
    OwnersBookings,
    PendingReservations,
    BookingsRequests,
    BookingRequests,
  },
  async created() {
    await this.getInitialContentFivesClub()
    this.index = null;
    this.bookingId=this.$route.params.bookingId || 0
    if (this.index != null) this.setTabIndex(this.index)
    if (this.$route.params.toEdit) this.setTabIndex(1)
  },
  data() {
    return {
      tabIndex: 0,
      index:0,
      bookingId:0,
    }
  },
  computed:{
    ...mapState("fivesClubCatalogs", ["pendingReservations","requestPendindAuthRsvs"]),
    filterParams() {
      const defaultParams = {
        hotel: null,
        numberHouse: null,
        rangeDate: null,
        nights: 0,
        adultsNumber: 1,
        numberChildren: 0,
        internalNotes: '', // para notas prearrival
        ninos: [],
        dateIn:'',
        dateOut:'',
        needAuthorization: false,
        reasonAuthorization : '',
        blackout: 0
      }
      return this.$route.params.toEdit ? this.$route.params : defaultParams
    }
  },
  methods: {
    ...mapMutations("mySales", ["setBookingSelected"]),
    ...mapActions('fivesClubCatalogs', ['getInitialContentFivesClub','fetchOwnerBookingFilter']),
    ...mapMutations("fivesClubCatalogs", ["clearDataSearchForm",'setIsLoadingPendingReservations','setPendingReservations','setPendindAuthRsvs']),
    clickTab(){
      this.clearDataSearchForm("")
      this.setBookingSelected(null);
    },
    async clickTabPendings(){
      this.clickTab()
      if( !this.pendingReservations.length ){
        this.setIsLoadingPendingReservations( true )
        const payload = !this.requestPendindAuthRsvs ? { IdStatus: 1, needAuthorization: 1} : this.requestPendindAuthRsvs 
        if(!this.requestPendindAuthRsvs){
          this.setPendindAuthRsvs(payload)
        }     
        const pendingReservations = await this.fetchOwnerBookingFilter(payload)    
        this.setPendingReservations( pendingReservations)
        this.setIsLoadingPendingReservations( false )
      }
    },
     setTabIndex( index ){      
      this.tabIndex = index    
    },
  },
};
</script>
