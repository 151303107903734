<template>
  <b-modal
    size="sm"
    :id="'authorize-pend-rsv' + detail.id"
    :title="detail.housingcode + ' | ' + detail.datein +' to '+ detail.dateout"
    ok-only
    hide-footer
    centered
  >
  
    <b-card-text>
      <div class="text-center">
        <h5>Autorizando reserva:</h5>
        <label><strong>{{detail.invoice}} | {{detail.code}}: {{detail.fullname}}</strong></label>
        <label>Motivo: {{ detail.dataAuthorization.length > 0 ? detail.dataAuthorization[0].reasonAuthorization : '' }}</label>
      </div>
      <div class="isCentered isSpinner" v-if="isLoading">
        <center>
          <b-spinner class="isCentered" label="Spinning"></b-spinner> <br>
            Acción en proceso
        </center>
      </div>            
      <b-row class="mb-2">
        <b-col md="12">
          <b-form-group label="Notas">
            <b-form-textarea
              v-model="note"
              placeholder="Escribe la nota de Autorizacion"
              :maxlength="100"
              rows="2"
            >
            </b-form-textarea>
          </b-form-group>
        </b-col>
          <b-col md="6">
          <div class="text-left">
            <b-button
              :disabled="note=='' || isLoading"
              variant="danger"
              @click="autorizeBookig(true)"
            >
              <b-spinner small v-if="isSaving" />No Autorizar
            </b-button>
          </div>
        </b-col>
        <b-col md="6">
          <div class="text-right">
            <b-button
              :disabled="note=='' || isLoading"
              variant="success"
              @click="autorizeBookig(false)"
            >
              <b-spinner small v-if="isSaving" />Confirmar
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card-text>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  
  props: {
    detail: {
      type: Object,
      required: true,
    },
    isLoading:{
        type: Boolean,
        required: true,
    }
  },
  data() {
    return {
      isSaving: false,
      note: "",
      selectedOption: null
    };
  },
  methods: {
    ...mapActions('fivesClubCatalogs', ['fetchBookingPreviousData']),
    async autorizeBookig(isCancel){
      let payload = {
        note: this.note,
        isCancel,
        id: this.detail.id,
        isReactivate:null
      }

      if (isCancel){ // Si deciden rechazar la autorización
        let swalert = {
          title: 'Cancelando reserva: ' + this.detail.housingcode + ' | ' + this.detail.datein +' to '+ this.detail.dateout,
          subtitle: "¿Desea cancelar o restaurar esta reserva?",
          message: "Al cancelar esta reserva, perderá la posibilidad de modificarla. También puede elegir si quiere restaurar la reserva.",
          message2: null,
          confirmButton: "Cancelar reserva",
          confirmVariant: 'danger',
          cancelButton: "Restaurar reserva",
          cancelVariant: 'warning',
          table: null,
          size: 'sm'
        }

        const valid = await this.autorizeCancelBookings(swalert) // valido si cancelo o reactivo la autorización

        if (valid) this.$emit('authorize-rsv-pending', payload) // Si cancela pasa directo
        else if (valid == false) { // Si escogieron reactivarla
          payload.isReactivate = 1 // Por defecto lo hará con los datos actuales
          payload.isCancel = false // y cancelo el rechazo por modificación

          const hasHistoric = await this.fetchBookingPreviousData(this.detail.id) // recibo infirmación acerca de datos anteriores
          if(valid == false && hasHistoric){
            swalert.title = 'Reactivando reserva: ' + this.detail.housingcode + ' | ' + this.detail.datein +' to '+ this.detail.dateout,
            swalert.subtitle = "Se han encontrado datos previos",
            swalert.message = "Esta reserva cuenta con datos antiguos. Debe elegir los datos a restaurar para continuar.",
            swalert.message2 = "(Tome en cuenta que los datos que eliga sobreescribirán los otros datos)"
            swalert.confirmButton = "Usar datos actuales",
            swalert.cancelButton = "Restaurar datos anteriores",
            swalert.size = 'md'
            swalert.confirmVariant = 'success',
            swalert.cancelVariant = 'info',

            swalert.table = {
              data: [
                {...hasHistoric, type: 'Anterior'},
                {datein: this.detail.datein, dateout: this.detail.dateout, type: 'Actual'},
              ],
              datafields:[
                { key: "type", label: "Reserva" },
                { key: "datein", label: "Check In" },
                { key: "dateout", label: "Check Out" }
              ],
            }

            const desition = await this.autorizeCancelBookings(swalert) // Valido si reactivar con datos anteriores o actuales

            if(desition) payload.isReactivate = 1 // 1 reactiva una reserva con datos actuales
            else if (desition == false) payload.isReactivate = 2 // 2 reactiva una reserva con datos anteriores
            else payload.isReactivate = null // cierra el modal
          }
          if (payload?.isReactivate != null) this.$emit('authorize-rsv-pending', payload)
        }
      } else {
        payload.isReactivate = 0 // 0 Autorizo directamente la reserva
        this.$emit('authorize-rsv-pending', payload)
      }
    },

    async autorizeCancelBookings(info){
      const h = this.$createElement

      const titleVNode = h('label', { domProps: { innerHTML: info.title } })
      const messageVNode = h('div', { class: ['foobar'] }, [
        h('h4', { class: ['text-center']}, [`${info.subtitle}`] ),
        h('p', { class: ['text-center mb-1'] }, [`${info.message} `,]),
        info.message2 ? h('p', { class: ['text-center mb-1'] }, [`${info.message2} `,]) : null,
        info.table ? h('b-table', {
          props: {
            items: info?.table?.data,
            fields: info?.table?.datafields,
            small: true,
            responsive: true,
            showEmpty: true,
            emptyText: "No matching records found",
          },
        }) : null,
      ])

      const modal = await this.$bvModal.msgBoxConfirm([messageVNode],{
        title: titleVNode,
        size: info.size,
        centered: true,
        okVariant: info.confirmVariant,
        okTitle: info.confirmButton,
        cancelVariant: info.cancelVariant,
        cancelTitle: info.cancelButton,
        cancelDisabled: true,
        hideHeaderClose: false,
        footerClass: 'text-center'
      })

      return modal
    },
  },
};
</script>


