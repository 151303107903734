<template>
	<div>		
		<b-form-group v-if="options.length > 0">            
			<v-select
        label="MealPlanDescriptionCustom"
				:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :options="options"
        v-model="selected"
        :clearable="false"
        @input="setRateSelected(selected)"
      >
    </v-select>   
		</b-form-group>  
    <b-alert show variant="danger"  class="mt-2" v-else>
      <div class="alert-body text-center"> 😣 <span>No se encontraron tarifas para esta habitación</span></div>
    </b-alert> 		
	</div>
</template>

<script>
import {mapMutations} from 'vuex'
import vSelect from 'vue-select'    
import { toJson } from '@/helpers/helpers'

export default {
  props:{
    room: {
      type: Object,
      required: true
    }   
  }, 
  components:{
    vSelect,
  }, 
  data(){
	  return {               
      options: this.room.rates,
      selected: this.room.detailSelected.MealPlanDescriptionCustom,
    }
  },
  methods:{      
    setRateSelected( rate ){
      // console.log( rate  )
      const payload = {
        detailSelected : toJson(rate), 
        uuid: this.room.uuid,
        divisa: { currencyid: 1, code: "USD", value: "1.00" }
      }
      if( rate ){         
        this.$emit('set-rate-selected', payload)       
      }      
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>