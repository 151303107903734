<template>
  <b-modal
    size="sm"
    :id="'modal-observations' + detail.id"
    :title="detail.code + ' | ' + detail.guestname"
    ok-only
    hide-footer
  >
    <b-card-text>
      <b-row class="mb-2">
        <b-col md="12">
          <b-form-group label="Nota de observaciones">
            <b-form-textarea
              v-model="detail.specialnotes"
              placeholder="Escribe la nota de observacione"
              :maxlength="100"
              rows="2"
            >
            </b-form-textarea>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <div class="text-right">
            <b-button
              :disabled="!toEdit"
              variant="primary"
              @click="observations(detail)"
            >
              <b-spinner small v-if="isSaving" />Confirmar
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card-text>
  </b-modal>
</template>

<script>

export default {
  props: {
    detail: {
      type: Object,
      required: true,
    },
    originalnote: {
      type: String,
      required: false
    }
  },
  watch:{
    specialnotes( value ){
      if (value != this.originalnote) this.toEdit = true
      else this.toEdit = false
    }
  },
  data() {
    return {
      isSaving: false,
      toEdit: false,
    }
  },
  computed: {
    specialnotes(){
      return this.detail.specialnotes
    }
  },
  methods: {
    async observations(item) {
      this.$emit("observationsDetailReserv", item)
      this.$emit("receive-reload")
      this.$root.$emit("bv::hide::modal", "modal-observations" + this.detail.id)
    },
  },
};
</script>
