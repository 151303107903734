<template>
    <div style="overflow-x:auto;">
        <b-table
            :sort-desc="true"
            :items="listPending"
            :fields="tableColumns"
            show-empty
            empty-text="Sin registros encontrados"
            class="position-relative mb-0 minHTable"
        >
            <template #cell(code)="row">
                <p class="d-flex justify-content-between align-items-center">
                    <span :class="row.item.reservanumber ? 'text-foliorsv':''">{{ row.item.reservanumber ? row.item.reservanumber : '--'}}</span>
                    <b-button variant="flat-primary" class="btn-icon" :id="'popover-button-variant'+row.item.id"
                    ><b-icon-info-circle-fill/>
                    </b-button>
                </p>
                <b-popover :target="'popover-button-variant'+row.item.id" variant="primary" triggers="focus">
                    <template #title>Datos de la reserva</template>
                    <b>Fecha de creación:</b> {{ formatDateInfo(row.item.createdate, "es") }} <br>
                    <b>Código reserva:</b> {{ row.item.code }} <br>
                    <b>Creado por:</b> {{ row.item.userName ? row.item.userName : '--' }} <br>
                    <b>Tipo de origen:</b> {{ row.item.originname ? row.item.originname : '--' }} <br>
                    <b>Reservación desde AX:</b> {{ row.item.ishistory ? 'Sí' : 'No' }} <br>
                </b-popover>
            </template>

            <template #cell(fullname)="row">
                <b>Vivienda: </b>{{row.item.fullname}} <br>
                <b>Huésped: </b>{{row.item.guestname}} <br>
                <b>Beneficio: </b><b-badge variant="primary">{{row.item.benefit}} </b-badge><br>
                <b>Tipo de Ocupante: </b>{{row.item.occupantname}}<br>
                <b>Noches: </b>{{ row.item.nights }}
            </template>

            <template #cell(adults)="row">
                <div>
                    Adultos: <b >{{row.item.adults}} </b>
                    Niños: <b >{{row.item.children}} </b>
                </div>
            </template>

            <template #cell(status)="row">
                <span class="text-nowrap">
                    <div v-if="row.item.status== 1 ">
                        <b-dropdown
                            variant="link"
                            toggle-class="p-0 mr-1"
                            text="Form"
                            no-caret
                            right
                            v-b-tooltip.hover title="Haga click para ver más detalles"
                        >
                            <template #button-content>
                                <b-badge href="#" pill class="bg-warning">
                                    {{row.item.statusname}} <feather-icon icon="AlertTriangleIcon"/>
                                </b-badge>
                            </template>

                                <div v-if="row.item.internalnotes || row.item.dataAuthorization != ''">
                                    <div class="m-1 text-center"> <b>Detalles</b></div><hr>
                                    <div class="m-1">
                                        <b>Razón autorización: {{row.item.dataAuthorization != '' ? row.item.dataAuthorization[0].reasonAuthorization : '--' }}</b>
                                    </div>
                                    <div v-html="row.item.internalnotes ? `<b>Notas</b><br> ${row.item.internalnotes.replace(/\n/g, '<br>')}` : ''" class="m-1"></div>
                                </div>
                                <div v-else>
                                    <p class="m-1 text-danger">No se tiene información adicional</p>
                                </div>
                        </b-dropdown>
                    </div>

                    <div v-if="row.item.status== 2 ">
                        <b-dropdown
                            variant="link"
                            toggle-class="p-0 mr-1"
                            text="Form"
                            no-caret
                            right
                            v-b-tooltip.hover title="Haga click para ver más detalles"
                        >
                            <template #button-content>
                                <b-badge href="#" pill class="bg-success">
                                    {{row.item.statusname}} <feather-icon icon="CheckCircleIcon"/>
                                </b-badge>
                            </template>
                            <div class="m-1">
                                <div class="mb-1 text-center"> <b>Detalles autorización</b></div><hr>
                                <p> <b>Autorizada por:</b> {{row.item.dataAuthorization != '' ? row.item.dataAuthorization[0].authorizationBy : ''}} <br> </p>
                                <p> <b>Fecha: </b> {{ row.item.dataAuthorization != '' ?  formatDateInfo(row.item.dataAuthorization[0].dateAuthorization, "es") : '' }}</p>    <p> <b>  Notas:</b> {{row.item.dataAuthorization != '' ? row.item.dataAuthorization[0].noteAuthorization : ''}}<br> </p>
                            </div>
                        </b-dropdown>
                    </div>
                    <div v-if="row.item.status== 3 ">
                        <b-dropdown
                            variant="link"
                            toggle-class="p-0 mr-1"
                            text="Form"
                            no-caret
                            right
                            v-b-tooltip.hover title="Haga click para ver más detalles"
                        >
                            <template #button-content>
                                <b-badge pill href="#" class="bg-danger">
                                    {{row.item.statusname}} <feather-icon icon="AlertCircleIcon"/>
                                </b-badge>
                            </template>
                            <div class="m-1">
                                <div class="mb-1 text-center"> <b>Detalles cancelación</b></div><hr>
                                <p v-if="row.item.userCancelName"> <b>Cancelada por:</b> {{row.item.userCancelName}} <br> </p>
                                <p v-if="row.item.cancellationDate">  <b>Fecha: </b>{{ formatDateInfo(row.item.cancellationDate, "es") }}</p>
                                <p v-if="row.item.cancelNotes"><b>Notas:</b> {{row.item.cancelNotes}}<br> </p>
                            </div>
                        </b-dropdown>
                    </div>
                    <b-badge v-if="row.item.status == 4 " pill class="bg-primary">
                        {{row.item.statusname}} <feather-icon icon="CheckCircleIcon" />
                    </b-badge>
                </span>
            </template>

            <template #cell(actions)="row">
                <b-dropdown
                    toggle-class="p-0"
                    no-caret
                    right
                >
                    <template #button-content>
                        <b-button variant="primary" size="sm">
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle" style="color: white" />
                        </b-button>
                    </template>

                    <b-dropdown-item :to="{ name: 'owners-Booking', params: { booking: 'booking-' + row.item.id+'-'+true } }">
                        <feather-icon icon="CalendarIcon" size="12" />
                        <span class="align-middle ml-50"> Detalle de reserva</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                        @click="openModalAuthorize(row.item)"
                        v-if="can('fivesclub_owner_booking_show_booking_auth_action_button_edit') && row.item.needAuthorization && row.item.status == 1"
                    >
                        <feather-icon icon="UnlockIcon" />
                        <span class="align-middle ml-50"> Autorizar Reserva</span>
                    </b-dropdown-item>
                </b-dropdown>
                <ModalAuthorize :detail="row.item" :isLoading="isAuthorizing" @authorize-rsv-pending="autorizeRSV" />
           </template>
        </b-table>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import ModalAuthorize from '@/modules/fivesClub/components/catalogs/ownersBookings/list/AuthorizeBooking'
import {  formatDateOnly, showAlertMessage } from '@/helpers/helpers';
import { acl } from "@/modules/auth/mixins/acl"

export default {
  mixins: [ acl],
    props:{
        listPending: {
            type: Array,
            required: true,
        },
        tableColumns: {
            type: Array,
            required: true,
        },
    },
    components:{
        ModalAuthorize
    },
    data(){
        return{
            isAuthorizing: false
        }
    },
    computed:{
        ...mapState('auth', ['user']),
        ...mapState("fivesClubCatalogs", ["requestPendindAuthRsvs"]),
    },
    methods:{
        ...mapActions('fivesClubCatalogs', [ 'sendAuthorization', 'fetchOwnerBookingFilter' ]),
        ...mapMutations('fivesClubCatalogs', ['setIsLoadingPendingReservations','setPendingReservations','setPendindAuthRsvs']),
        formatWordBreaking(notes){
            return notes.replace(/\n/g, "<br>")
        },
        formatDateInfo(fecha, locale) {
            return formatDateOnly(fecha, locale);
        },
        async autorizeRSV(data){
            this.isAuthorizing = true
            const { isCancel, note, id } =  data
            const payload = { idOwnerBooking: id, AuthorizationBy: this.user.idUser, noteAuthorization: note, isCancel }
            const { status, message } = await this.sendAuthorization(payload)
            if(!isCancel) this.msgAuthorizeBooking(status, message)
            if(isCancel) this.msgCancelBooking(status, message)
            this.isAuthorizing = false
            this.$root.$emit('bv::hide::modal', 'authorize-pend-rsv' + data.id)
            await this.getRsvsPending()
        },
        openModalAuthorize(item) {
            this.$root.$emit('bv::show::modal', 'authorize-pend-rsv' + item.id)
        },
        msgAuthorizeBooking(status, message){
            if(status) showAlertMessage( 'Ok: Se autorizó la reserva', 'InfoIcon', message, 'success', 4000, 'bottom-right' )
            else showAlertMessage( 'Error: No se pudo autorizar la reserva', 'InfoIcon', message, 'danger', 4000, 'bottom-right' )
        },
        msgCancelBooking(status, message){
            if(status) showAlertMessage( 'Ok: Se canceló la reserva', 'InfoIcon', message, 'success', 4000, 'bottom-right' )
            else showAlertMessage( 'Error: No se pudo cancelar la reserva','InfoIcon', message, 'danger', 4000, 'bottom-right' )
        },
        async getRsvsPending(){
            this.setIsLoadingPendingReservations( true )
            const pendingReservations = await this.fetchOwnerBookingFilter(this.requestPendindAuthRsvs)
            if(!pendingReservations.length) showAlertMessage( 'Sín resultados','InfoIcon', 'No se encontraron resultados', 'success', 4000, 'bottom-right' )
            this.setPendingReservations( pendingReservations)
            this.setIsLoadingPendingReservations( false )
        }
    }
}
</script>

<style scoped>
.popSize{
  width: 350px!important;
}

.minHTable{
  min-height: 350px;
}
</style>