<template>
    <div >
        <div class="border p-1 mb-1">
            <div class="head-filter">
                <h4>Filtros</h4>        
                <div class="float-right"><b >Total de Registros: </b> <b-badge variant="success" class="mr-1" >  {{pendingReservations.length}} </b-badge></div>
            </div>
            <validation-observer tag="form" ref="filterPendings" v-slot="{ invalid }" >
                <b-form @submit.prevent="handlerFilter"> 
                    <b-row>  
                        <b-col md="2">
                            <b-form-group label="Indicar fechas">
                                <b-form-checkbox v-model="searchFilter.useDates" name="check-button" switch/>
                            </b-form-group>
                        </b-col>                       
                        <b-col md="2">
                            <b-form-group label="Fecha inicio">
                                <b-form-input                                
                                    type="date"                                
                                    v-model="searchFilter.checkin"
                                    :disabled="!searchFilter.useDates"
                                >
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="2">
                            <b-form-group label="Fecha Fin">
                                <b-form-input                                
                                    type="date"                            
                                    v-model="searchFilter.checkout"
                                    :disabled="!searchFilter.useDates"
                                >
                                </b-form-input>
                            </b-form-group>
                        </b-col>   
                       <b-col md="2">
                            <validation-provider name="status" rules="required">
                            <b-form-group slot-scope="{ valid, errors }" label="Status">
                            <b-form-select
                                :state="errors[0] ? false : valid ? true : null"
                                v-model="searchFilter.status"
                                :clearable="false"
                            >
                                <option disabled value="null">Seleccione una opción</option>
                                <option
                                v-for="status in statusRsv"
                                :key="status.id"
                                :value="status.id"
                                >
                                {{ status.name }}
                                </option>
                            </b-form-select>
                            <b-form-invalid-feedback>
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                       </b-col>                                   
                    </b-row>
                    <div class="buttons-filter">
                        <div>
                        <b-button                
                            @click="resetFilters"
                            variant="warning"   
                            :disabled="invalid || isLoadingPendingReservations"
                        > Reset
                        </b-button>      
                        </div>            
                        <b-button
                            type="submit"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            :disabled="invalid || isLoadingPendingReservations"
                        > Buscar
                    </b-button>                     
                    </div>        
                </b-form>
            </validation-observer>
        </div>  
        <div class="border p-1 mb-1 table-filter">
            <b-row>
                <b-col md="2">
                    <label>Numero de registros</label>
                    <v-select
                        v-model="porPagina"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="perPageOptions"
                        :clearable="false"
                        class="per-page-selector"
                        @input="changePerPage"
                    />
                </b-col>
                <b-col md="4">
                    <label>Buscar en tabla</label>
                    <div class="d-flex">
                        <b-form-input
                            v-model="filter.queryPendingRsvs"
                            class="mr-1"
                            placeholder="Buscar..."
                            type="search"
                            @input="setFilterInTable"
                        />
                    </div>
                </b-col>
            </b-row>
        </div>      
    </div>
</template>
<script>
import * as moment from 'moment';
import { mapMutations, mapState, mapActions } from 'vuex'
import { showAlertMessage, currentDate } from '@/helpers/helpers'
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
// import { statusOwnerBooking } from "@/data/fivesClub";

export default {
    directives: { Ripple },
     props: {
        perPage: {
            type: Number,
            required: true,
        },
        perPageOptions: {
            type: Array,
            required: true,
        },
    },
    components: {
        vSelect,
    },
     data(){
        return {
            porPagina: this.perPage,
            searchFilter: {
                checkin: currentDate(),
                checkout: currentDate(), 
                status: null,
                useDates: false,             
            },
        }
    },
    computed: {        
        ...mapState('fivesClubCatalogs', ['pendingReservations','filter','isLoadingPendingReservations']),       
        statusRsv(){
            // const statusValid = ['Pendiente', 'Confirmada', 'Cancelada']
            // return statusOwnerBooking.filter( status => statusValid.includes(status.name))
            return [
                { id: 1, name: 'Pendientes' },
                { id: 2, name: 'Autorizadas' },
                { id: 3, name: 'No autorizadas' },                
            ]
        }
    },   
    methods:{
        ...mapActions('fivesClubCatalogs', ['fetchOwnerBookingFilter']),
        ...mapMutations('fivesClubCatalogs', ['setPendingReservations','setQueryPendingRsvs','setIsLoadingPendingReservations','setPendindAuthRsvs']),           
        async handlerFilter(){
            const { useDates, checkin, checkout, status  } = this.searchFilter
            if(useDates){
                const isValidDates = this.validateDates()
                if(!isValidDates) return                
            }
            const payload = { checkin, checkout, IdStatus: parseInt(status), needAuthorization: 1}
            if(!useDates){
                delete payload.checkin
                delete payload.checkout
            }            
            this.setIsLoadingPendingReservations( true )
            this.setPendindAuthRsvs(payload)
            const pendingReservations = await this.fetchOwnerBookingFilter(payload)
            if(!pendingReservations.length){
                showAlertMessage( 'Sín resultados','InfoIcon', 'No se encontraron resultados', 'warning', 4000, 'bottom-right' )
            } 
            this.setPendingReservations( pendingReservations)
            this.setIsLoadingPendingReservations( false )
        },
        validateDates(){
            const { checkin, checkout } = this.searchFilter
            const isValidDates = moment(checkout).isSameOrAfter(checkin, 'day')
            if(!isValidDates){
                showAlertMessage("Fechas incorrectas","BellIcon", `😡 Fecha fin no debe ser menor a fecha de inicio`, "danger", 3000, "bottom-right" )
                this.searchFilter.checkin = currentDate()
                this.searchFilter.checkout = currentDate()
            }
            return isValidDates
        },
        resetFilters(){            
            this.setPendingReservations([])
            Object.assign(this.$data, this.$options.data())
            this.$refs.filterPendings.reset()          
            this.setQueryPendingRsvs('')  
            this.porPagina = 10
            this.setPendindAuthRsvs(null)
        },
        changePerPage(perPage) {
            this.$emit('change-per-page', perPage)
            this.porPagina = perPage
        },
        setFilterInTable(string) {      
            this.setQueryPendingRsvs(string)                  
        },
    }
}
</script>
<style scoped>
    .head-filter{
        display: flex;
        justify-content: space-between;
        margin-block-end: 1rem;
    }
    .buttons-filter {
        display: flex;
        justify-content: space-between;
    }
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>