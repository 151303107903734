var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"size":"sm","id":'modal-cancelReserv' + _vm.detail.id,"title":_vm.detail.code + ' | ' + _vm.detail.guestname,"ok-only":"","centered":"","hide-footer":""}},[_c('b-card-text',[_c('div',{staticClass:"d-flex justify-content-between "},[_c('b-badge',{attrs:{"variant":"primary"}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"MoonIcon"}}),_vm._v(" "),_c('span',[_vm._v(" "+_vm._s(_vm.detail.nights)+" noches")])],1),_c('b',[_vm._v(_vm._s(_vm.detail.datein)+" "),_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"ArrowRightIcon"}}),_vm._v(_vm._s(_vm.detail.dateout))],1)],1),_c('br'),_c('div',{staticClass:"text-center pb-0"},[(_vm.canCancelDirectly)?_c('h4',[_c('strong',[_vm._v("Cancelando reserva")])]):(!_vm.canCancelDirectly && _vm.detail.status == 6)?_c('h4',[_c('strong',[_vm._v("Solicitud de cancelación enviada")])]):_c('h4',[_c('strong',[_vm._v("Solicitando confirmación de cancelación")])])]),(_vm.isLoading)?_c('div',{staticClass:"isCentered isSpinner mt-2 mb-1"},[_c('center',[_c('b-spinner',{staticClass:"isCentered",attrs:{"label":"Spinning"}}),_vm._v(" "),_c('br'),_vm._v(" Acción en proceso ")],1)],1):_vm._e(),_c('validation-observer',{attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',[_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"motivo de cancelación","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Motivo de cancelación"}},[_c('b-form-select',{attrs:{"disabled":_vm.detail.status == 6,"state":errors[0] ? false : valid ? true : null,"clearable":false},model:{value:(_vm.cancelRsv.motiveCancel),callback:function ($$v) {_vm.$set(_vm.cancelRsv, "motiveCancel", $$v)},expression:"cancelRsv.motiveCancel"}},[_c('option',{domProps:{"value":null}},[_vm._v("Seleccione una opción")]),_vm._l((_vm.cancellationReasons),function(motivo){return _c('option',{key:motivo.id,domProps:{"value":motivo.id}},[_vm._v(" "+_vm._s(motivo.name)+" ")])})],2),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),(_vm.cancelRsv.requiresPenalty)?_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"penalización por noches","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Penalización por noches"}},[_c('b-form-input',{attrs:{"disabled":_vm.detail.status == 6,"placeholder":"Indique noches de penalización","state":errors[0] || !_vm.isValidNigths.valid ? false : valid ? true : null},on:{"keypress":_vm.onlyNumber,"change":function($event){return _vm.minumunCancelPenalty(_vm.cancelRsv)}},model:{value:(_vm.cancelRsv.penaltyNights),callback:function ($$v) {_vm.$set(_vm.cancelRsv, "penaltyNights", $$v)},expression:"cancelRsv.penaltyNights"}}),_c('b-form-invalid-feedback',[(errors[0])?_c('span',[_vm._v(_vm._s(errors[0])+". "),_c('br')]):_vm._e(),(!_vm.isValidNigths.maxNights)?_c('span',[_vm._v("El máximo de noches es de "+_vm._s(_vm.detail.nights)+". "),_c('br')]):_vm._e(),(!_vm.isValidNigths.minNights)?_c('span',[_vm._v("El minino de noches es de "+_vm._s(_vm.penaltyNightsMinimun)+".")]):_vm._e()])],1)}}],null,true)})],1):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"notas de cancelación","rules":"max:200|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Nota de cancelación"}},[_c('b-form-textarea',{attrs:{"disabled":_vm.detail.status == 6,"placeholder":"Escriba la nota de cancelación","maxlength":200,"rows":"2","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.cancelRsv.cancelNote),callback:function ($$v) {_vm.$set(_vm.cancelRsv, "cancelNote", $$v)},expression:"cancelRsv.cancelNote"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),(_vm.canCancelDirectly && _vm.detail.status == 6)?_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"notas de cancelación","rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Nota de cancelación"}},[_c('b-form-textarea',{attrs:{"placeholder":"Escriba la nota de cancelación","maxlength":50,"rows":"2","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.cancelRsv.extraCancelNote),callback:function ($$v) {_vm.$set(_vm.cancelRsv, "extraCancelNote", $$v)},expression:"cancelRsv.extraCancelNote"}}),_vm._l((errors),function(error){return _c('b-form-invalid-feedback',{key:error},[_vm._v(" "+_vm._s(error)+" ")])})],2)}}],null,true)})],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}}),(_vm.detail.status != 6)?_c('b-col',{attrs:{"md":"12"}},[(_vm.canCancelDirectly)?_c('label',{staticClass:"text-center",staticStyle:{"color":"#d90032"}},[_vm._v("*Esta acción cancelará esta reserva definitivamente, por lo que no podrá regresarla a su status anterior.")]):_vm._e(),_c('div',{staticClass:"pt-1 text-right"},[_c('b-button',{attrs:{"type":"button","disabled":(invalid || !_vm.isValidNigths.valid ) || _vm.isLoading,"variant":_vm.canCancelDirectly ? 'danger':'primary'},on:{"click":function($event){$event.preventDefault();return _vm.cancelBoooking(_vm.canCancelDirectly ? 1 : 0)}}},[_vm._v(" "+_vm._s(_vm.canCancelDirectly ? 'Cancelar' : 'Solicitar')+" ")])],1)]):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('b-row',[(_vm.detail.status == 6)?_c('b-col',{attrs:{"md":"6"}},[_c('b-button',{attrs:{"type":"button","disabled":invalid || _vm.isLoading,"variant":"warning"},on:{"click":function($event){$event.preventDefault();return _vm.manageReactivation()}}},[_vm._v(" Reactivar "),_c('br'),_vm._v(" reserva ")])],1):_vm._e(),(_vm.canCancelDirectly && _vm.detail.status == 6)?_c('b-col',{staticClass:"text-right",attrs:{"md":"6"}},[_c('b-button',{attrs:{"type":"button","disabled":invalid  || _vm.isLoading,"variant":"danger"},on:{"click":function($event){$event.preventDefault();return _vm.manageTrueCancelBoooking()}}},[_vm._v(" Confirmar "),_c('br'),_vm._v(" Cancelación ")])],1):_vm._e()],1)],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }