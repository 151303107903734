<template>
  <div>
    <Filters
      :perPage="perPage"
      :perPageOptions="perPageOptions"
      :isLoadingExcelOwnerBookings="isLoadingExcelOwnerBookings"
      :isLoadingOwnerBookings="isLoadingOwnerBookings"
      :ownerBookingsData="ownersBookingData"
      :originalOwnersBookingData="currentBookings"
      @change-per-page="changePerPage"
      @get-owners-bookings="getOwnersBookings"
      @get-excel-owners-bookings="getExcelOwnersBookings"
      @set-filtered-table-items="setFilteredTableItems"
      @reset-owners-booking="resetOwnersBooking"
    />
    <br />
    <TableOwnerBooking
      v-if="ownersBookingData.length && !isLoadingOwnerBookings"
      :ownersBookings="ownersBookingData"
      :bookingId="bookingId"
      :tableColumns="tableColumns"
      :sortBy="sortBy"
      :isSortDirDesc="isSortDirDesc"

    />
    <div class="isCentered isSpinner" v-if="isLoadingOwnerBookings">
      <center>
        <b-spinner class="isCentered" label="Spinning"></b-spinner> <br>
        Cargando reservas de propietarios
      </center>
    </div>
    <div class="text-center" v-if="!ownersBookingData.length && !isLoadingOwnerBookings">
      <b-alert variant="danger" show>
        <div class="alert-body text-center">
          <span><strong>¡Sin registros aún!</strong> No se encontraron reservaciones, realize una busqueda .</span>
        </div>
      </b-alert>
    </div>

      <PaginationTable
        :currentPage="currentPage"
        :totalRows="filteredOwnersBookings.length"
        :perPage="perPage"
        @change-page="changePage"
        v-if="filteredOwnersBookings.length && !isLoadingOwnerBookings"
      />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters  } from 'vuex'
import Filters from '@/modules/fivesClub/components/catalogs/ownersBookings/list/Filters'
import TableOwnerBooking from '@/modules/fivesClub/components/catalogs/ownersBookings/list/TableOwnersBooking'
import PaginationTable from '@/modules/fivesClub/components/catalogs/ownersBookings/list/PaginationTable'

export default {
  async mounted(){
    const memberships = await this.fetchMembershipsInfo({typeReq: 'title'})
    this.setMembershipsTitles(memberships)
    const benefits = await this.fetchBenefitInfo({title: true, nights: true})
    this.setBenefitTitles(benefits)
    if(this.bookingId)this.updateInfo(this.bookingId)
  },
  components: {
    TableOwnerBooking,
    PaginationTable,
    Filters,
  },
  props:{
    bookingId: {
		type: Number,
		required: true,
    },
    
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      perPageOptions: [5, 10, 15, 20, 25, 50, 100],
      sortBy: 'id',
      isSortDirDesc: false,
    
      isLoadingExcelOwnerBookings: false,
      originalBookings: null,
      currentBookings: null,
      tableColumns: [
        { key: 'invoice', label: 'Número de Orden' },
        { key: 'fullname', label: 'Información' },
        { key: 'datein', label: 'Fecha de Llegada', sortable: true, class: 'text-center'  },
        { key: 'dateout', label: 'Fecha de Salida ',  sortable: true, class: 'text-center' },

        { key: 'adults', label: 'Pax', class: 'text-center' },
        { key: 'status', label: 'Status', class: 'text-center' },
        { key: 'actions', label: 'Acciones', class: 'text-center' },
      ],
    };
  },
  computed: {
    ...mapState('fivesClubCatalogs', ['ownersBookings','ownerBookingFastFilter','isLoadingOwnerBookings']),
    ...mapGetters('fivesClubCatalogs', ['filteredOwnersBookings']),
    ownersBookingData(){
      const bookings = this.filteredOwnersBookings
      let showBookings
      this.currentBookings = bookings
      if (!this.originalBookings) this.originalBookings = structuredClone(bookings)
      if (this.ownerBookingFastFilter) showBookings = this.ownerBookingFastFilter.slice( (this.currentPage - 1) * this.perPage, this.currentPage * this.perPage )
      else showBookings = this.currentBookings?.slice( (this.currentPage - 1) * this.perPage, this.currentPage * this.perPage )
      return showBookings
    },
  },
  methods: {
    ...mapActions('fivesClubCatalogs', ['fetchOwnerBookingFilter', 'fetchMembershipsInfo', 'fetchBenefitInfo', 'fetchExcelOwnersBookings','fetchOwnerBookingId']),
    ...mapMutations('fivesClubCatalogs', ['setOwnersBookings','setOwnersBookingsId','setOwnersBookingsFatsFilters','setIsLoadingOwnersBookings', 'setBenefitTitles', 'setMembershipsTitles']),
    changePage(page) {
      this.currentPage = page;
    },
    changePerPage(perPage) {
      this.perPage = perPage;
    },
    resetOwnersBooking(){
      this.currentBookings = null
      this.originalBookings = null
      this.setOwnersBookingsFatsFilters(null)
    },
    async getOwnersBookings(payload) {
      this.setIsLoadingOwnersBookings(true) 
      const ownersBookings = await this.fetchOwnerBookingFilter(payload)
      this.setOwnersBookings(ownersBookings)
      this.setIsLoadingOwnersBookings(false) 
    },
    async getExcelOwnersBookings(payload) {
      this.isLoadingExcelOwnerBookings = true
      await this.fetchExcelOwnersBookings(payload)
      this.isLoadingExcelOwnerBookings = false;
    },
    async updateInfo(id) {
		this.setIsLoadingOwnersBookings(true)
      const newdata = await this.fetchOwnerBookingId(id)
      // Al traer un arreglo, solicito siempre el primer objeto ya que se solicita por id
      this.setOwnersBookingsId( newdata[0])
      this.setIsLoadingOwnersBookings(false)
    },
    setFilteredTableItems(payload){
      const {filterData, status} = payload
      if (filterData && status && status != 0) this.setOwnersBookingsFatsFilters(structuredClone(filterData))
      else  this.setOwnersBookingsFatsFilters(null)
    }
  },
};
</script>
<style scoped>
.isCentered{
  align-content: center;
  align-items: center;
}
  .isSpinner{
  padding: 2rem;
}
</style>