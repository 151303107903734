<template>
    <div class="border p-1 mb-1">
        <h4>Buscar vivienda</h4>
        <validation-observer tag="form" v-slot="{ invalid }" ref="searchHouseForm">
            <b-form @submit.prevent="searchOwnersHousing">
                <b-row>
                    <b-col md="4">
                        <validation-provider name="Hoteles" rules="required" >
                            <b-form-group slot-scope="{ valid, errors }" label="Seleccione hotel*" >
                                <b-form-select
                                    :state="errors[0] ? false : valid ? true : null"
                                    v-model="idHotel"
                                >
                                    <option :value="null">Seleccione hotel</option>
                                    <option v-for="hotel in hotels" :key="hotel.id" :value="hotel.id">
                                        {{ hotel.name }}
                                    </option>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ errors[0] }} </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="Vivienda" rules="required">
                            <b-form-group slot-scope="{ valid, errors }" label="Numero de vivienda*">
                                <b-form-input
                                    v-model="numberHouse"
                                    type="number"
                                    @keypress="onlyNumber"
                                    :state="errors[0] ? false : valid ? true : null"
                                    placeholder="Buscar Vivienda"
                                />
                                <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <b-form-group label="Acciones">
                            <b-button @click="resetRequestsRsv" variant="warning" class="mr-1" :disabled="!showBtnReset"> Limpiar </b-button>
                            <b-button type="submit" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" :disabled="invalid" > Buscar </b-button>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { utils } from '@/modules/fivesClub/mixins/utils'
import * as moment from 'moment'

export default {
    directives: { Ripple },
    mixins: [utils],
    props:{
        ownerHousing:{
			type: Object,
            default: null
		},
    },
    data() {
        return {
            numberHouse: null,
            idHotel: null,
            currentYear: new Date().getFullYear(),
        };
    },
    computed: {
        ...mapState('start', ['hotels']),
        showBtnReset(){
            return this.ownerHousing || this.numberHouse || this.idHotel
        }
    },
    methods:{
        ...mapActions('fivesClubCatalogs', ['getHousingOwners']),
        ...mapActions('fivesClubContracts', ['fetchContracts']),        
        ...mapMutations('shop', ['setOwners']),
        async searchOwnersHousing(){
            this.$emit('set-benefit-contract', { hasBenefits: false, contractEnded: true, contractCancelled: true })
            this.$emit('set-is-loading-owners', true)
            this.setOwners([])
            this.$emit('set-owner-housing', null)
            this.$emit('set-id-housing', null)
            this.$emit('set-contract', null)
            this.$emit('set-benefits', [])
            this.$emit('set-rooms', [])
            this.$emit('set-year', this.currentYear)
            const owner = await this.fetchContracts({ number: this.numberHouse, IdResorts: this.idHotel })
            const dataOwner = owner.length > 0 ? owner[0] : null
            // console.log(dataOwner)
            if(dataOwner){
                this.$emit('set-id-housing', dataOwner.housingid)
                this.$emit('set-owner-housing', dataOwner)
                this.setOwners(dataOwner.owners)
                this.$emit('set-owner', dataOwner.owners[0])
                const contract = { ...dataOwner }
                delete contract.documents 
                delete contract.owners 
                const contractIsValid  = this.checkIfContractIsStillValid(contract )
                this.$emit('set-contract', contract)
                this.$emit('set-benefit-contract', contractIsValid)
            }
            this.$emit('set-is-loading-owners', false)

        },
        checkIfContractIsStillValid(contract){ 
            const contractValid = { hasBenefits: false, contractEnded: true, contractCancelled: true }   
            //fecha fin de contrato es antes de la fecha actual                                                              
            const endDateContract = moment(contract.dateEnd).isBefore(this.currentDate) 
            contractValid.contractEnded = endDateContract && contract.membershipcode != "CLASSIC"                             
            contractValid.contractCancelled = false
            return contractValid
        },
        resetRequestsRsv(){
            this.numberHouse = null
            this.idHotel = null
            this.setOwners([])
            this.$emit('set-id-housing', null)
            this.$emit('set-owner-housing', null)
            this.$emit('set-contract', null)
            this.$emit('set-benefits', [])
            this.$emit('set-rooms', [])
            this.$emit('set-year', this.currentYear)
            this.$refs.searchHouseForm.reset(); //reset form
        }
    }
}
</script>