<template>
    <div>        
        <b-alert show variant="info" v-if="!ownerHousing && !isLoadingOwners">
            <div class="alert-body text-center">  <span>Se mostrarán resultados una vez se haya hecho la busqueda</span></div>
        </b-alert>
        <div class="loadOwners" v-if="isLoadingOwners">            
            <b-spinner label="Spinning"></b-spinner>
            <p>Cargando propietarios</p>                                              
        </div>        
        <div v-if="ownerHousing" class="border p-1">            
            <h4 class="text-center">{{  ownerHousing.fullname  }}</h4>
            <table class="table table-sm table-striped">
                <thead>
                    <tr>
                    <th scope="col">Correo</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">telefono</th>
                    <th scope="col">Usar</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in ownerHousing.owner" :key="index">
                    <td scope="row">{{ item.ownerEmail }}</td>
                    <td scope="row">{{ item.ownerName + " " + item.ownerLastname }}</td>
                    <td scope="row">{{ item.ownerPhone }}</td>
                    <td scope="row">
                        <b-button variant="primary" class="btn-add" @click="handlerGetBenefits(item.idOwner)">
                        <feather-icon icon="PlusIcon" size="16" />
                        </b-button>
                    </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
    props:{
        isLoadingOwners:{
            type: Boolean,
            required: true
        },
        idHousing:{
            type: Number,
            default: 0
        },
        currentYear:{
            type: Number,
            required: true
        },
        ownerHousing:{
			type: Object,
            default: null			
		},
    },
       
    methods:{
        handlerGetBenefits(idOwner){
            const payload = { numberHouse: this.idHousing, idOwner, year: this.currentYear }            
            this.$emit('get-benefits', payload)
        }
    }
}
</script>

<style scoped>
.loadOwners{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 1rem;
}
</style>