<template>
  <b-modal
    size="sm"
    :id="'modal-folio' + detail.id"
    :title="detail.code"
    ok-only
    hide-footer
  >
    <b-card-text>
        <ValidationObserver ref="folio" v-slot="{ invalid }" >   
      <b-row class="mb-2">
        <b-col md="12">
                 
            <validation-provider name="Código de Confirmación" rules="required">
              <b-form-group slot-scope="{ valid, errors }" label="Código de Confirmación">
                <b-form-input
                  class="form-control"
                  type="text"
                   :disabled="!detail.toEdit"
                  v-model="detail.folio"
                  placeholder="Escriba un código de Confirmación"
                  :state="errors[0] ? false : valid ? true : null"
                />
                  <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
        

        </b-col>
        <b-col md="6">
          <div class="text-right">
            <b-form-checkbox
              v-model="detail.toEdit"
                            switch
                            inline
                            :unchecked-value="false"
              class="custom-control-success"
              @change="changeStatus($event)"
            >
            Modificar código
            </b-form-checkbox>
            </div>
        </b-col>
        
        <b-col md="6">
          <div class="text-right">
            <b-button
              :disabled="!detail.toEdit || invalid"
              variant="primary"
              @click="addFolio(detail)"
            >
              <b-spinner small v-if="isSaving" />Confirmar
            </b-button>
          </div>
        </b-col>
              

      </b-row>
        </ValidationObserver>
    </b-card-text>
  </b-modal>
</template>

<script>

export default {
  props: {
    detail: {
      type: Object,
      required: true,
    },
    folioInfo: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      isSaving: false,
      toEdit: false,
      status:false
    }
  }, watch:{
    folioValid( value ){
      if (value != this.folioInfo) this.toEdit = true
      else this.toEdit = false
    }
  },
  computed: {
   
  },
  methods: {
    async addFolio(item) {
      this.isSaving=true
      this.$emit("folioDetail", item)
      this.$emit("receive-reload")
      item.folio=""
      this.isSaving=false
      this.$root.$emit("bv::hide::modal", "modal-folio" + this.detail.id)
    },
     changeStatus(event){
     this.isSaving=true
     
        this.isSaving=false
      }
  },
};
</script>
