<template>
    <div>
       <SearchAvailabilityForm
            :benefits="benefits"
            :year="currentYear"
            :oneBenefitIsSelected="oneBenefitIsSelected"
            :owner="owner"
            :contract="contract"
            :rooms="rooms"
            :filterParams="filterParams"
            @set-year="setYear"
            @set-benefits="setBenefits"
            @set-rooms="setRooms"
            @set-show-rooms="setShowRooms"
            @set-booking-requests="setShowRooms"
            @set-is-loading-owners="setIsLoadingOwners"
            @set-is-loading-benefits="setIsLoadingBenefits"
            @set-id-housing="setIdHousing"
            @set-contract="setContract"
            @set-owner="setOwner"
            @set-booking-request="setBookingRequest"
            @set-booking-availibility="setBookingAvailibility"            
       />
       
       
       <div class="text-center" v-show="!benefits.length && isLoadingBenefits">
            <b-spinner label="Loading..." />
            <p>Cargando beneficios</p>
       </div>
       
       <Benefits  
            v-show="benefits.length"          
            :currentYear="currentYearBenefits"
            :contract="contract"
            :benefits="benefits"
            @set-year-benefit="setYearBenefits"
            @set-benefits="setBenefits"
            @set-is-loading-benefits="setIsLoadingBenefits"
       />

       <RoomsList
            :isLoadingRooms="isLoadingRooms"
            :benefits="benefits"
            :rooms="rooms"
            :contract="contract"
            :benefitSelected="benefitSelected"            
            :bookingRequest="bookingRequest"
            :owner="owner"
            :showRooms="showRooms"
            :bookingAvailibility="bookingAvailibility"
       />

    </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import SearchHousing from '@/modules/fivesClub/components/catalogs/ownersBookings/requests/SearchHousing'
import OwnersList from '@/modules/fivesClub/components/catalogs/ownersBookings/requests/OwnersList'
import Benefits from '@/modules/fivesClub/components/catalogs/ownersBookings/requests/Benefits'
import SearchAvailabilityForm from '@/modules/fivesClub/components/catalogs/ownersBookings/requests/SearchAvailabilityForm'
import RoomsList from '@/modules/fivesClub/components/catalogs/ownersBookings/requests/RoomsList'



export default {
    components: {
        SearchHousing,
        OwnersList,
        Benefits,
        SearchAvailabilityForm,
        RoomsList,
    },
    props:{
        filterParams:{
            type: Object,
            required: true
        }
    },
    data(){
        return {
            isLoadingOwners: false,
            isLoadingBenefits: false,
            isLoadingRooms: false,
            currentYear: new Date().getFullYear(),            
            currentYearBenefits: new Date().getFullYear(),            

            idHousing: null,
            owner: null,
            contract: null, // toman valor de la respuesta del action getContractOwner
            benefits: [], // toman valor de la respuesta del action fetchBenefitsByYearContract
            // rooms: [], // toman valor de la respuesta del action getRooms
            hasBenefits: true,
            contractEnded: true,
            contractCancelled: true,            
            bookingRequest: null, //toma valor de función searchRooms
            rooms:[],
            showRooms: false,
            bookingAvailibility: null,
        }
    },
    computed:{
        ...mapState('start', ['hotels']),
        /*Verifico si al menos un beneficio está seleccionado */
        oneBenefitIsSelected(){
            return this.benefits.some(benefit => benefit.isSelected)
        },
        benefitSelected(){
			return this.benefits.find( benefit => benefit.isSelected )
		},
        invalidContract(){
            return this.hasBenefits || this.contractEnded || this.contractCancelled
        }
    },
    methods: {
        ...mapActions('fivesClubCatalogs', ['getContractOwner', 'fetchBenefitsByYearContract' ]),
        ...mapActions('mySales', ['getRooms',]),
        setIfContractIsValid(booleans){
            const { hasBenefits, contractEnded, contractCancelled } = booleans
            this.hasBenefits = hasBenefits
            this.contractEnded = contractEnded
            this.contractCancelled = contractCancelled
        },
        setIsLoadingOwners(bool){
            this.isLoadingOwners = bool
        },
        setIsLoadingBenefits(bool){
            this.isLoadingBenefits = bool            
        },
        setIdHousing(idHousing){
            this.idHousing = idHousing
        },
        setOwner(owner){
            this.owner = owner
        },
        setContract(contract){
            this.contract = contract
        },       
        setBenefits(benefits){
            this.benefits = benefits
        },
        setRooms(rooms){
            this.rooms = rooms
        },
        setShowRooms(boolean){
            this.showRooms = boolean
        },
        setBookingRequest(bookingRequest){
            this.bookingRequest = bookingRequest
        },
        setYear(year){
            this.currentYear = year
        },
        setYearBenefits(year){
            this.currentYearBenefits = year
        },      
        setBookingAvailibility(data){
            this.bookingAvailibility = data
        },
    }
}
</script>