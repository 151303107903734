<template>
  <b-modal
    size="sm"
    :id="'modal-cancelReserv' + detail.id"
    :title="detail.code + ' | ' + detail.guestname"
    ok-only
    centered
    hide-footer
  >
    <b-card-text>
      <div class="d-flex justify-content-between ">
        <b-badge variant="primary" > <feather-icon icon="MoonIcon" class="mr-25" /> <span> {{detail.nights}} noches</span> </b-badge>
        <b>{{detail.datein}} <feather-icon icon="ArrowRightIcon" class="mr-25" />{{detail.dateout}}</b>
      </div>
      <br>

      <div class="text-center pb-0">
        <h4 v-if="canCancelDirectly"><strong>Cancelando reserva</strong></h4>
        <h4 v-else-if="!canCancelDirectly && detail.status == 6"><strong>Solicitud de cancelación enviada</strong></h4>
        <h4 v-else><strong>Solicitando confirmación de cancelación</strong></h4>
      </div>

      <div class="isCentered isSpinner mt-2 mb-1" v-if="isLoading">
        <center>
          <b-spinner class="isCentered" label="Spinning"></b-spinner> <br>
          Acción en proceso
        </center>
      </div>

      <validation-observer tag="form" v-slot="{ invalid }" >
        <b-form>

          <b-row class="mb-2">
            <b-col md="12">
              <validation-provider name="motivo de cancelación" rules="required">
                <b-form-group slot-scope="{ valid, errors }" label="Motivo de cancelación">
                  <b-form-select
                    :disabled="detail.status == 6"
                    :state="errors[0] ? false : valid ? true : null"
                    v-model="cancelRsv.motiveCancel"
                    :clearable="false"
                  >
                    <option :value="null">Seleccione una opción</option>
                    <option v-for="motivo in cancellationReasons" :key="motivo.id" :value="motivo.id"> {{ motivo.name }} </option>
                  </b-form-select>
                  <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="12" v-if="cancelRsv.requiresPenalty">
              <validation-provider name="penalización por noches" rules="required">
                <b-form-group slot-scope="{ valid, errors }" label="Penalización por noches">
                  <b-form-input
                    :disabled="detail.status == 6"
                    placeholder="Indique noches de penalización"
                    @keypress="onlyNumber"
                    @change="minumunCancelPenalty(cancelRsv)"
                    v-model="cancelRsv.penaltyNights"
                    :state="errors[0] || !isValidNigths.valid ? false : valid ? true : null"
                  />
                  <b-form-invalid-feedback>
                    <span v-if="errors[0]">{{ errors[0] }}. <br></span>
                    <span v-if="!isValidNigths.maxNights">El máximo de noches es de {{detail.nights}}. <br></span>
                    <span v-if="!isValidNigths.minNights">El minino de noches es de {{penaltyNightsMinimun}}.</span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="12">
              <validation-provider name="notas de cancelación" rules="max:200|required">
                <b-form-group label="Nota de cancelación" slot-scope="{ valid, errors }">
                  <b-form-textarea
                    :disabled="detail.status == 6"
                    v-model="cancelRsv.cancelNote"
                    placeholder="Escriba la nota de cancelación"
                    :maxlength="200"
                    rows="2"
                    :state="errors[0] ? false : valid ? true : null"
                  />
                  <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="12" v-if="canCancelDirectly && detail.status == 6">
              <validation-provider name="notas de cancelación" rules="required|max:50">
                <b-form-group label="Nota de cancelación" slot-scope="{ valid, errors }">
                  <b-form-textarea
                    v-model="cancelRsv.extraCancelNote"
                    placeholder="Escriba la nota de cancelación"
                    :maxlength="50"
                    rows="2"
                    :state="errors[0] ? false : valid ? true : null"
                  />
                  <b-form-invalid-feedback v-for="error in errors" :key="error"> {{ error }} </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6"></b-col>
            <b-col md="12" v-if="detail.status != 6">
              <label style="color:#d90032" class="text-center" v-if="canCancelDirectly">*Esta acción cancelará esta reserva definitivamente, por lo que no podrá regresarla a su status anterior.</label>
              <div class="pt-1 text-right">
                <b-button type="button" @click.prevent="cancelBoooking(canCancelDirectly ? 1 : 0)"
                  :disabled="(invalid || !isValidNigths.valid ) || isLoading" :variant="canCancelDirectly ? 'danger':'primary'">
                  {{ canCancelDirectly ? 'Cancelar' : 'Solicitar'}}
                </b-button>
              </div>
            </b-col>
            <b-col md="12">
              <b-row>
                <b-col md="6" v-if="detail.status == 6">
              <b-button type="button" @click.prevent="manageReactivation()" :disabled="invalid || isLoading"
                variant="warning">
                Reactivar <br> reserva
              </b-button>
            </b-col>
            <b-col md="6" v-if="canCancelDirectly && detail.status == 6" class="text-right">
              <b-button type="button" @click.prevent="manageTrueCancelBoooking()"
                :disabled="invalid  || isLoading" variant="danger">
                Confirmar <br> Cancelación
              </b-button>
            </b-col>
          </b-row>
            </b-col>
          </b-row>

        </b-form>
      </validation-observer>
    </b-card-text>
  </b-modal>
</template>

<script>

import * as moment from 'moment'
import { mapState, mapActions } from "vuex"
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { utils } from '@/modules/fivesClub/mixins/utils';
import { currentDate } from '@/helpers/helpers'
import { acl } from "@/modules/auth/mixins/acl"

export default {
  mixins: [utils, acl],
  directives: { Ripple },
  created(){
    this.penaltyNightsMinimun = Boolean(this.detail.nights) ? (this.detail.nights < 3 ? this.detail.nights : 3 ) : 0
  },
  props: {
    detail: {
      type: Object,
      required: true,
    },
    isLoading:{
      type: Boolean,
      required: true,
    },
    cancelRsv: {
      type: Object,
    }
  },
  components: { vSelect, },
  data() {
    return {
      penaltyNightsMinimun:1,
      currentDate: currentDate()
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('fivesClubCatalogs', ['cancellationReasons']),
    motivoCancel(){
      return this.cancellationReasons?.find(creason => creason.id == this.cancelRsv?.motiveCancel)?.name
    },
    canCancelDirectly(){
      return this.can('fivesclub_owner_booking_show_booking_reservation_list_action_button_auth_cancel')
    },
    isValidNigths(){
      const maxNights = this.cancelRsv?.penaltyNights <= this.detail?.nights
      const minNights = this.cancelRsv?.penaltyNights >= this.penaltyNightsMinimun
      return {valid: maxNights && minNights, maxNights, minNights}
    }
  },
  methods: {
    ...mapActions('fivesClubCatalogs', ['fetchCancelOwnerBookingInfo']),
    cancelBoooking(confirm){
      const { cancelNote, penaltyNights, motiveCancel, requiresPenalty } = this.cancelRsv
      const payload = {
        id: this.detail?.id,
        user: this.user?.idUser,
        motiveCancel,
        cancelNote,
        nights: this.detail?.nights,
        penaltynights: requiresPenalty ? penaltyNights : 0, // si penaliza, indica las noches
        isCxl: confirm // indico si es una confirmación de cancelación (0), si procede (1) o no (2)
      }
      this.$emit('cancel-rsv-owner', payload)
    },
    async manageTrueCancelBoooking(){
      const swalert = {
        title: "Cancelando reserva: " + this.detail.code + ' | ' + this.detail.guestname,
        subtitle: `¿Desea cancelar esta reserva?`,
        message: "Al cancelar esta reserva, no se podrá recuperar.",
        confirmButton: "Cancelar reserva",
        cancelButton: "No Cancelar reserva",
        confirmVariant: 'danger mx-auto',
        cancelVariant: 'hidden'
      }

      const valid = await this.autorizeCancelBookings(swalert)
      if (valid) {
        const newNotes = structuredClone(this.cancelRsv?.extraCancelNote) || ''
        if (this.canCancelDirectly) this.cancelRsv.cancelNote = newNotes
        this.cancelBoooking(1) // voy a cancelar
      }
    },
    async manageReactivation(){
      const swalert = {
        title: "Reactivando reserva: " + this.detail.code + ' | ' + this.detail.guestname,
        subtitle: "¿Desea cancelar esta solicitud?",
        message: "Esto hará que la reservación se reactive y pase a estar pendiente de modificación.",
        confirmButton: "Reactivar reserva",
        cancelButton: "Cancelar",
        confirmVariant: 'success mx-auto',
        cancelVariant: 'hidden',
      }
      const valid = await this.autorizeCancelBookings(swalert)

      if (valid) {
        const newNotes = structuredClone(this.cancelRsv?.extraCancelNote) || ''
        if (this.canCancelDirectly) this.cancelRsv.cancelNote = newNotes
        this.cancelBoooking(2) // voy a restaurar la reserva
      }
    },

    async autorizeCancelBookings(info){
      const h = this.$createElement

      const titleVNode = h('label', { domProps: { innerHTML: info.title } })

      const messageVNode = h('div', { class: ['foobar'] }, [
        h('h4', { class: ['text-center']}, [`${info.subtitle}`] ),
        h('p', { class: ['text-center mb-1'] }, [`${info.message}`,]),
      ])

      const modal = await this.$bvModal.msgBoxConfirm([messageVNode],{
        title: titleVNode,
        size: 'sm',
        centered: true,
        okTitle: info.confirmButton,
        okVariant: info.confirmVariant,
        cancelTitle: info.cancelButton,
        cancelVariant: info.cancelVariant,
        cancelDisabled: true,
        hideHeaderClose: false,
      })

      return modal
    },
    minumunCancelPenalty(){
      if (!this.isValidNigths?.valid) this.cancelRsv.penaltyNights = structuredClone(this.penaltyNightsMinimun)
    }
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.btn-hidden{
  display: none;
  visibility: hidden;
}
</style>

