<template>
    <div class="border-benfits-membership">
        <app-collapse >
            <app-collapse-item title="Beneficios">              
                <b-row>
                    <b-col md="2">
                        <b-form-select v-model="selectedYear" :options="years" @change="setYearBenefit" class="header__select" />
                    </b-col>
                    <b-col md="2">
                        <b-button class="btn" variant="primary" @click="getBenefitsByYear"> Buscar </b-button>                                  
                    </b-col>
                </b-row>
                
                <div class="text-center">
                    <h5>Beneficios {{ selectedYear }}</h5>
                </div>

                <div class="table-responsive mt-2">
                    <table class="table table-sm table-striped" >
                        <thead>
                            <tr>
                                <th scope="col">Nombre</th>
                                <th scope="col">Cantidad</th>
                                <th scope="col">Usado</th>
                                <th scope="col">Disponible</th>                            
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in benefits" :key="index">
                            <td scope="row">{{ item.benefitname }}</td>
                            <td scope="row">{{ item.benefitqty }}</td>
                            <td scope="row">{{ item.used }}</td>
                            <td scope="row">{{ item.balance }}</td>                           
                            </tr>
                        </tbody>
                    </table>           
                </div>
            </app-collapse-item>
        </app-collapse>
    </div>
</template>

<script>

import { mapActions  } from 'vuex'
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue"
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue"

export default {
    components: {
        AppCollapse,
        AppCollapseItem,   
    },
    props:{ 
        contract:{
            type: Object,
            default: null
        },     
        currentYear:{
            type: Number,
            required: true
        }, 
        benefits:{
            type: Array,
            required: true
        },  
    },
    mounted(){
        this.selectedYear = this.currentYear
    },
    data(){
        return {
            selectedYear: null,            
        }
    },
    computed: {
        years(){
            const currentYear = new Date()
            return [ currentYear.getFullYear(), currentYear.getFullYear() + 1]
        }
    },
    methods:{
		...mapActions('fivesClubCatalogs', ['fetchBenefitsByYearContract']),
        setYearBenefit(year){
            this.selectedYear = year
            this.$emit('set-year-benefit', year)                        
        },
        async getBenefitsByYear(){
            this.$emit('set-benefits', [])				
			this.$emit('set-is-loading-benefits', true)
            const payload = { idContract: this.contract.id, year: this.selectedYear, typeBenefit:'Noches' }
			const rawBenefits = await this.fetchBenefitsByYearContract(payload)
            const benefits =  rawBenefits.length ? this.formatBenefits(rawBenefits) : []            
            this.$emit('set-benefits', benefits)				
			this.$emit('set-is-loading-benefits', false)            
        },
        formatBenefits( rawBenefits ){
            let benefits = []
            rawBenefits?.forEach( beneficio => {
                const { benefit } = beneficio
                //solo necesito los que vienen en false
                if( !benefit.includebooking ){
                    benefit.showCheckSelect = benefit.code !== "TA"
                    benefit.isSelected = false // por defecto no está marcado
                    benefits.push( benefit )
                }
            })        
            return benefits
        },
    }
}
</script>

<style scoped>
.border-benfits-membership {
  border: 1px solid #333366;
  margin-bottom: 2.5rem;
}
.year{
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
}
</style>