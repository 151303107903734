<template>
    <div>   
        <Filters
            :perPage="perPage"
            :perPageOptions="perPageOptions"
            :searchQuery="searchQuery"
            @change-per-page="changePerPage"            
           
        />     
        <ListPending
            v-if="!isLoadingPendingReservations"
            :listPending="listPendingData"
            :tableColumns="tableColumns"
        />
        <div class="isCentered isSpinner" v-else>
            <center>
                <b-spinner class="isCentered" label="Spinning"></b-spinner> <br>
                Cargando reservas pendientes por autorizar
            </center>
        </div>
        <PaginationTable
            :currentPage="currentPage"
            :totalRows="filteredPendingRsvs.length"
            :perPage="perPage"
            @change-page="changePage"
            v-if="listPendingData.length && !isLoadingPendingReservations"
        />
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import Filters from '@/modules/fivesClub/components/catalogs/ownersBookings/pendings/Filters'
import ListPending from '@/modules/fivesClub/components/catalogs/ownersBookings/pendings/ListPending'
import PaginationTable from '@/modules/fivesClub/components/catalogs/ownersBookings/list/PaginationTable';

export default {
    components:{
        Filters,
        ListPending,
        PaginationTable
    },
    data(){
        return {
            perPage: 10,
            currentPage: 1,
            perPageOptions: [5, 10, 15, 20, 25, 50, 100],
            searchQuery: "",        
            tableColumns: [
                { key: 'code', label: 'Folio reserva' },
                { key: 'fullname', label: 'Información' },
                { key: 'datein', label: 'Fecha de Llegada', sortable: true, class: 'text-center'  },
                { key: 'dateout', label: 'Fecha de Salida ',  sortable: true, class: 'text-center' },

                { key: 'adults', label: 'Pax', class: 'text-center' },
                { key: 'status', label: 'Status', class: 'text-center' },
                { key: 'actions', label: 'Acciones', class: 'text-center' },
            ],
        }
    },
    computed:{
        ...mapState('fivesClubCatalogs', ['pendingReservations','isLoadingPendingReservations']),
        ...mapGetters('fivesClubCatalogs', ['filteredPendingRsvs']),       
        listPendingData(){
            return this.filteredPendingRsvs?.slice( (this.currentPage - 1) * this.perPage, this.currentPage * this.perPage )
        }
    },
    methods:{
        changePage(page) {
            this.currentPage = page;
        },
        changePerPage(perPage) {
            console.log('llego aqui')
            this.perPage = perPage;
        },
    }
    
}
</script>